<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <PendingCard
      v-if="generals"
      :date="timestamp"
      :status="generals.status"
      :message="generals.status === 'rejected' ? generals.document.tickets[0].comment : generals.status === 'success' ? $t('generalDocument.success-msg') : $t('generalDocument.pending-new-msg')"
    />
    <b-button
      block
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'dark' : ''"
      class="d-xxl-none mb-1"
      @click="isHidden = !isHidden"
    >
      <div>
        <span class="text-black">{{ $t('document_general_guide') }}</span>
        <feather-icon
          variant="black"
          :class="$store.state.appConfig.layout.isRTL ? 'left-position' : 'right-position'"
          :icon="isHidden ? 'ChevronDownIcon' : 'ChevronUpIcon'"
          size="20"
        />
      </div>
    </b-button>
    <b-row>
      <b-col
        class="col-xl-7"
        xl="12"
        md="12"
        sm="12"
      >
        <b-card
          class="h-100 d-xxl-none"
          :class="(isHidden ? 'd-none' : '')
          "
        >
          <b-button
            v-if="isCryptoMode"
            block
            variant="success"
            class="my-2"
            @click="commitmentTextModal = true"
          >
            {{ $t('commitment_text') }}
          </b-button>
          <div class="d-md-flex text-center">
            <b-img
              fluid
              width="160"
              :src="isCryptoMode ? require('@/assets/images/image/3.webp') : require('@/assets/images/image/crypto3.png')"
            />
            <p class="align-self-center mt-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetGeneralPage.generalImage1Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetGeneralPageWithoutCommitment.generalImage1Description') }}
              </span>
            </p>
          </div>
          <div
            class="d-md-flex text-center"
            dir="rtl"
          >
            <b-img
              fluid
              width="160"
              :src="isCryptoMode ? require('@/assets/images/image/2.webp') : require('@/assets/images/image/crypto2.png')"
            />
            <p class="align-self-center mt-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetGeneralPage.generalImage2Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetGeneralPageWithoutCommitment.generalImage2Description') }}
              </span>
            </p>
          </div>
          <div class="d-md-flex text-center">
            <b-img
              fluid
              width="160"
              :src="isCryptoMode ? require('@/assets/images/image/4.webp') : require('@/assets/images/image/crypto4.png')"
            />
            <p class="align-self-center mt-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetGeneralPage.generalImage3Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetGeneralPageWithoutCommitment.generalImage3Description') }}
              </span>
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col
        class="col-xxl-5"
        xl="12"
        md="12"
        sm="12"
      >
        <b-card
          id="general_document"
          :title="$t('general')"
          class="h-100"
        >
          <GetDescription name="document-general-description" />
          <validation-observer ref="generalTab">
            <!-- form -->
            <b-form class="mt-2">
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  :label="$t('id_type')"
                  label-for="ID Type"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('id_type')"
                    vid="ID Type"
                    rules="required"
                  >
                    <v-select
                      id="ID Type"
                      v-model="id_type"
                      item-value="id"
                      label="translation_identity"
                      name="ID Type"
                      autocomplete="off"
                      :placeholder="$t('id_type')"
                      :options="idTypes"
                      @input="changeLable"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  :label="$t(lable)"
                  label-for="national-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(lable)"
                    vid="national-id"
                    :rules="id_type?(id_type.identity === 'identity_certificate' ? 'required' : id_type.identity === 'passport' ? 'required|passportValidate' : ''):''"
                  >
                    <b-form-input
                      v-model="NationalId"
                      :placeholder="$t(placeholder)"
                      name="id_number"
                      autocomplete="nope"
                      @keypress="getCitizenship === 'IR' ? onlyNumbers($event) : AlphaNumeric($event)"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="id_type&&id_type.identity === 'identity_certificate'"
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  :label="$t('identity_certificate_number')"
                  label-for="identityCertificate"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(lable)"
                    vid="identityCertificate"
                    rules="required|numeric"
                  >
                    <b-form-input
                      v-model="identityCertificate"
                      :placeholder="$t('identity_certificate_number')"
                      name="identityCertificate"
                      autocomplete="nope"
                      @keypress="getCitizenship === 'IR' ? onlyNumbers($event) : AlphaNumeric($event)"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="id_type&&id_type.identity !== 'identity_certificate'"
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  label-for="expiration-date"
                  :label="$t('document_general_expiration_date')"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="expiration_date_ref"
                    name="expiration-date"
                    vid="expiration-date"
                    rules="required"
                  >
                    <date-picker
                      id="expiration-date"
                      v-model="expiration_date"
                      :color="primaryColor"
                      :locale="citizenship === 'iran' ? lable === 'national-id' ? 'fa' : 'en' : 'en'"
                      type="date"
                      :placeholder="$t('document_general_expiration_date')"
                      :disabled="!id_type"
                      auto-submit
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-alert
                  v-if="citizenship === 'iran' && lable === 'national-id'"
                  class="px-1 py-1"
                  :show="lable === 'national-id' && convertedExpiration"
                >
                  <div :class="$store.state.appConfig.layout.skin === 'dark' && 'text-white'">
                    <span>{{ $t('exipiration_data_in_gregorian') }}</span>
                    <span> : </span>
                    <span dir="ltr">{{ convertedExpiration || '' }}</span>
                  </div>
                </b-alert>
              </b-col>
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-alert
                  show
                  variant="danger"
                  class="p-1"
                >
                  <span class="pre-line">
                    {{ $t('uploadImageFromGallery') }}
                  </span>
                </b-alert>
                <b-form-group
                  :label="$t('document_general_front_photo')"
                  label-for="file_front"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('document_general_front_photo')"
                    vid="file_front"
                    :rules="'image|required|size:' + MAX_IMAGE_UPLOAD_SIZE"
                  >
                    <div class="row">
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <!-- file_front input -->
                        <b-form-file
                          id="file_front"
                          ref="file_front"
                          v-model="file_front"
                          :placeholder="$t('no_file_chosen')"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </div>

                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <!-- file_back input -->
                <b-form-group
                  v-if="id_type&&id_type.identity === 'nationality_identification_card'"
                  :label="$t('document_general_back_photo')"
                  vid="file_back"
                  label-for="file_back"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('document_general_back_photo')"
                    :rules="'image|required|size:' + MAX_IMAGE_UPLOAD_SIZE"
                  >
                    <div class="row">
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <b-form-file
                          id="file_back"
                          ref="file_back"
                          v-model="file_back"
                          accept="image/png, image/jpg, image/jpeg"
                          :placeholder="$t('no_file_chosen')"
                        />
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <!-- file_back input -->
                <b-form-group
                  :label="$t('document_general_proof_photo')"
                  label-for="file_proof"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('document_general_proof_photo')"
                    :rules="'image|required|size:' + MAX_IMAGE_UPLOAD_SIZE"
                    vid="file_proof"
                  >
                    <div class="row">
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <b-form-file
                          id="file_proof"
                          ref="file_proof"
                          v-model="file_proof"
                          accept="image/png, image/jpg, image/jpeg"
                          :placeholder="$t('no_file_chosen')"
                        />
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-if="!pending"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  :disabled="generals && generals.status == 'pending'"
                  @click="submit"
                >
                  {{ $t('save_changes') }}
                </b-button>

                <b-button
                  v-if="pending"
                  variant="primary"
                  class="mt-2 mr-1"
                  disabled
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                  <span>{{ $t('loading') }}</span>
                </b-button>
                <div
                  v-if="generals && generals.status == 'pending'"
                  class="mt-1"
                >
                  <b-alert
                    show
                    class="p-1"
                    variant="primary"
                  >
                    <span :class="$store.state.appConfig.layout.skin === 'dark' && 'text-white'">{{ $t('yourDocumentIsUnderReview') }}</span>
                  </b-alert>
                </div>
                <div
                  v-if="isPreparingFiles"
                  class="text-center"
                >
                  <h5 class="mt-2">
                    {{ $t('preparingFiles') }}
                  </h5>
                  <b-spinner
                    variant="primary"
                    label="Spinning"
                  />
                </div>
                <div
                  v-if="uploadSize > 0"
                  class="text-center"
                >
                  <h5 class="mt-2">
                    {{ $t('uploading') }}
                  </h5>
                  <b-progress
                    :value="currentUpload"
                    :max="uploadSize"
                    show-progress
                    animated
                    dir="ltr"
                    height="20px"
                  />
                </div>
              </b-col>

            </b-form>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col
        class="col-xxl-7"
        xl="12"
        md="12"
        sm="12"
      >
        <b-card class="h-100 d-none d-xxl-block">
          <b-button
            v-if="isCryptoMode"
            variant="success"
            class="my-2 position-absolute"
            :style="isRTL ? 'left : 2rem' : 'right : 2rem'"
            @click="commitmentTextModal = true"
          >
            {{ $t('commitment_text') }}
          </b-button>
          <br>
          <div class="d-md-flex mt-1">
            <b-img
              fluid
              width="160"
              :src="isCryptoMode ? require('@/assets/images/image/3.webp') : require('@/assets/images/image/crypto3.png')"
            />
            <p class="align-self-center mt-1 mx-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetGeneralPage.generalImage1Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetGeneralPageWithoutCommitment.generalImage1Description') }}
              </span>
            </p>
          </div>
          <div
            class="d-md-flex"
            :dir="$store.state.appConfig.layout.isRTL ? 'ltr' : 'rtl'"
          >
            <b-img
              fluid
              width="160"
              :src="isCryptoMode ? require('@/assets/images/image/2.webp') : require('@/assets/images/image/crypto2.png')"
            />
            <p
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              class="align-self-center mt-1 mx-1"
            >
              <span v-if="isCryptoMode">
                {{ $t('doucumnetGeneralPage.generalImage2Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetGeneralPageWithoutCommitment.generalImage2Description') }}
              </span>
            </p>
          </div>
          <div class="d-md-flex">
            <b-img
              fluid
              width="160"
              :src="isCryptoMode ? require('@/assets/images/image/4.webp') : require('@/assets/images/image/crypto4.png')"
            />
            <p class="align-self-center mt-1 mx-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetGeneralPage.generalImage3Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetGeneralPageWithoutCommitment.generalImage3Description') }}
              </span>
            </p>

          </div>

        </b-card>

      </b-col>
    </b-row>

    <b-modal
      v-model="commitmentTextModal"
      :title="$t('commitment_text')"
      centered
      size="lg"
      hide-footer
    >
      <template>
        <h4 class="mt-1">
          {{ $t('commitment_modal_title') }}
        </h4>
        <!-- <b-card :show-footer="false">
          <b-card-body> -->
        <hr class="my-2">
        <p>{{ $t('commitment_modal_content',{brand_name:getBrandName()}) }}</p>
        <h6>{{ $t('firstName_lastName') }}</h6>
        <h6>{{ $t('date') }}</h6>
        <h6>{{ $t('signature') }}</h6>
        <!-- </b-card-body>
        </b-card> -->
      </template>
    </b-modal>

  </b-overlay>
</template>
<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BImg,
  BFormFile,
  BOverlay,
  BModal,
  BAlert,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, size, passportValidate, numeric,
} from '@validations'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DatePicker from 'vue-persian-datetime-picker'
import jalaali from 'moment-jalaali'
import moment from '@/libs/moment'
import { alphanumeric } from '@/mixins/alphanumeric'
import showErrorMessages from '@/mixins/showErrorMessages'
import checkFileSize from '@/mixins/checkFileSize'
import PendingCard from '@/views/documents/PendingCard.vue'
import GeneralApis from '@/services/apis/documents/general'
import ProfileApis from '@/services/apis/profile'
import { toEnglishDigits, resizeImage } from '@/utils'
import GetDescription from '@/modules/forex/views/metatrader/components/getDescription.vue'

const profile = new ProfileApis()
const general = new GeneralApis()
export default {
  components: {
    GetDescription,
    BImg,
    BForm,
    BRow,
    BCard,
    BFormFile,
    BButton,
    BCol,
    BOverlay,
    BFormInput,
    BFormGroup,
    BSpinner,
    vSelect,
    PendingCard,
    BModal,
    DatePicker,
    BAlert,
    BProgress,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric, showErrorMessages, checkFileSize],
  data() {
    return {
      NationalId: '',
      identityCertificate: '',
      company_name: '',
      id_type: '',
      idTypes: [],
      company_national_id: '',
      registration_city: '',
      registration_date: '',
      company_type_id: '',
      required,
      passportValidate,
      size,
      numeric,
      pending: false,
      expiration_date: '',
      file_front: null,
      file_proof: null,
      file_back: null,
      generals: '',
      show: false,
      isHidden: true,
      lable: 'national-id',
      placeholder: 'national-id',
      commitmentTextModal: false,
      currentUpload: 0,
      uploadSize: 0,
      isPreparingFiles: false,
    }
  },
  computed: {
    ...mapGetters(['allGeneralDocuments', 'getCitizenship', 'getMaxUploadSize']),

    maxUploadSize() {
      return this.getMaxUploadSize
    },
    timestamp() {
      return this.generals && this.generals.document ? moment((this.generals.document.tickets.lengths > 0 ? this.generals.document.tickets[0].updated_at : this.generals.document.updated_at)).format('YYYY-MM-DD') : ''
    },
    isRTL() {
      return this.$store.state.appConfig.layout.isRTL
    },
    primaryColor() {
      return process.env.VUE_APP_PRIMARY_COLOR
    },
    convertedExpiration() {
      return jalaali(this.expiration_date, 'jYYYY/jMM/jDD').format('YYYY-MM-DD')
    },
    citizenship() {
      return this.$store.state.auth?.user?.citizenship?.name.toLowerCase()
    },
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
    isCryptoMode() {
      return process.env.VUE_APP_ACTIVE_MODULES.includes('CRYPTO')
    },
  },

  watch: {
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'document_general') {
          await this.getData()
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },

  async created() {
    await this.getData()
    await this.getList()
    this.show = false
  },
  methods: {
    ...mapActions(['saveDocumentGeneral']),
    async getData() {
      this.show = true
      await general.getDataInfo().then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.generals = res.data.results[0]
      }).finally(() => {
        this.show = false
      })
    },
    async getList() {
      await general.getIdTypeList().then(res => {
        this.idTypes = res.data.results
      })
    },
    async submit() {
      if (await this.$refs.generalTab.validate()) {
        const imageSize = (process.env.VUE_APP_RESIZE_IMAGE_SIZE ? process.env.VUE_APP_RESIZE_IMAGE_SIZE : 3072) * 1024
        this.isPreparingFiles = true
        this.pending = true

        const fileFront = document.getElementById('file_front')?.files[0]
          ? await resizeImage(document.getElementById('file_front')?.files[0], imageSize)
          : null

        const fileProof = document.getElementById('file_proof')?.files[0]
          ? await resizeImage(document.getElementById('file_proof')?.files[0], imageSize)
          : null

        const fileBack = document.getElementById('file_back')?.files[0]
          ? await resizeImage(document.getElementById('file_back')?.files[0], imageSize)
          : null

        const data = {
          id_number: toEnglishDigits(this.NationalId),
          identity_type_id: this.id_type.id,
          file_front: fileFront,
          file_proof: fileProof,
          file_back: fileBack,
          id_expiration_date: this.id_type && this.id_type.identity !== 'identity_certificate' ? this.convertedExpiration : undefined,
          id_number2: this.id_type && this.id_type.identity === 'identity_certificate' ? this.identityCertificate : undefined,
        }
        this.isPreparingFiles = false
        await general.SaveGeneralInfo(data, e => {
          this.uploadSize = e.total
          this.currentUpload = e.loaded
        }).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          if (!this.generals) {
            this.generals = { status: '' }
            this.generals.status = 'pending'
          } else {
            this.generals.status = 'pending'
          }
          this.getData()
          profile.get()
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }).finally(() => {
          this.pending = false
          this.uploadSize = 0
          this.currentUpload = 0
          this.isPreparingFiles = false
        })
      }
    },
    changeLable() {
      if (this.id_type) {
        if (this.id_type.identity === 'nationality_identification_card') {
          this.lable = 'national-id'
          this.placeholder = 'national-id'
        } else if (this.id_type.identity === 'passport') {
          this.lable = 'passport'
          this.placeholder = 'passport'
        } else if (this.id_type.identity === 'identity_certificate') {
          this.lable = 'national-id'
          this.placeholder = 'national-id'
        }
      }
    },
  },

  baseUrl() {
    return process.env.VUE_APP_BASE_IMAGE_URL
  },
}
</script>
<style>
.right-position {
  float: right;
}

.left-position {
  float: left;
}
.pre-line{
  white-space: pre-line;
}
</style>
